import axios from "axios"
import * as React from "react"

const Newsletter = props => {
  const [message, setMessage] = React.useState("Sign up")
  const [buttonDisabled, setButtonDisabled] = React.useState(false)
  const { title, description, image } = props

  const validateEmail = email => {
    var re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  const onSubmit = e => {
    e.preventDefault()
    e.stopPropagation()
    let email = e.target.elements.email.value
    if (validateEmail(email)) {
      setButtonDisabled(true)
      axios
        .post("https://a4u0zoa7le.execute-api.us-east-2.amazonaws.com/store", {
          email: e.target.elements.email.value,
          list_id: "reinventing",
        })
        .then(() => setMessage("Thank you!"))
    }
  }

  if (props.style === "bare") {
    return (
      <form
        id="signup-form"
        onSubmit={onSubmit}
        method="post"
        action="#"
        style={{ marginBottom: 0 }}
      >
        <h5 style={{ paddingLeft: ".25em", marginBottom: "0.5em" }}>{title}</h5>
        <div className="row gtr-uniform">
          <div className="col-8">
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email Address"
            />
          </div>
          <div className="col-4">
            <input
              type="submit"
              className={`button primary fit ${buttonDisabled && "disabled"}`}
              value={message}
            />
          </div>
        </div>
      </form>
    )
  }
  return (
    <section>
      <div className="row" style={!image ? { flexFlow: "column" } : {}}>
        {image && (
          <div className="col-6 col-12-small">
            <span className="image fit">
              <img
                src="https://picsum.photos/seed/newsletter3/950/500"
                alt=""
              />
            </span>
          </div>
        )}

        <div
          className={`${!image ? "col-8" : "col-6"} col-12-small`}
          style={!image ? { alignSelf: "center" } : {}}
        >
          <h2>{title}</h2>
          <p>{description}</p>
          <form id="signup-form" onSubmit={onSubmit} method="post" action="#">
            <div className="row gtr-uniform">
              <div className="col-8 col-12-small">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email Address"
                />
              </div>
              <div className="col-4 col-12-small">
                <input
                  type="submit"
                  className={`button primary fit ${
                    buttonDisabled && "disabled"
                  }`}
                  value={message}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Newsletter
