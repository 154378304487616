import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { CONSTANTS } from "../components/constants"
import Layout from "../components/layout"
import Newsletter from "../components/newsletter"
import Seo from "../components/seo"
import HappyPlaceWebp from "../images/landing/happyplace@2x.webp"
import HomeOfficeWebp from "../images/landing/homeoffice@2x.webp"
import Logo from "../images/logo"

const Landing = () => (
  <Layout header={false} landingWrapper={true}>
    <Seo
      meta={[
        {
          property: `og:url`,
          content: `${CONSTANTS.URL}/`,
        },
      ]}
    />

    <div className="landing-logo">{Logo()}</div>

    <section
      className="landing-section extra bg-image right"
      style={{ backgroundImage: `url(${HappyPlaceWebp})` }}
    >
      <h2>
        Meaningful climate actions for
        <br />
        your everyday life.
      </h2>
      <div className="row">
        <div className="col-12-small col-7 col-9-medium">
          <p>
            Climate change can be overwhelming. We’re here to help you make a
            difference with clear, trustworthy guides to climate solutions for
            your home, car, and lifestyle.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12-small col-9-medium col-6">
          <Newsletter
            style="bare"
            title="Sign up for our newsletter to get started"
          />
        </div>
      </div>
    </section>

    <section className="landing-section bg-image small">
      <StaticImage
        placeholder="tracedSVG"
        src="../images/landing/happyplace@2x.png"
        alt="Drawing of a woman sitting next to plant looking at her phone and notes. There is a dog laying down next to her."
      />
    </section>

    <div className="landing-bg-color light">
      <section className="landing-section bg-color light">
        <h2 className="tight">Focus on what matters</h2>
        <p>Changing a few things can have a big impact. </p>
        <div className="row aln-center">
          <article>
            <StaticImage
              placeholder="tracedSVG"
              src="../images/landing/housing.svg"
              alt='A donut chart showing that "housing" constitutes 34% of carbon emissions'
            />
            <h5>Housing</h5>
            <p>
              Did you know your house is a major source of emissions? Green
              renovations are often government subsidized, and they make your
              home more comfortable and save you money while helping the
              environment.
            </p>
          </article>
          <article>
            <StaticImage
              placeholder="tracedSVG"
              src="../images/landing/transportation.svg"
              alt='A donut chart showing that "transportation" constitutes 30% of carbon emissions'
            />
            <h5>Transportation</h5>
            <p>
              Driving a hybrid or electric car makes a meaningful difference to
              your emissions and will be a key part of the transition. We answer
              your questions and help you decide what type of car is right for
              you.
            </p>
          </article>
          <article>
            <StaticImage
              placeholder="tracedSVG"
              src="../images/landing/food.svg"
              alt='A donut chart showing that "food" constitutes 17% of carbon emissions'
            />
            <h5>Food</h5>
            <p>
              What we eat matters. Even small changes to your diet can have an
              outsized impact. Hundreds of companies and farmers are working on
              building a more sustainable food ecosystem.
            </p>
          </article>
          <article>
            <StaticImage
              placeholder="tracedSVG"
              src="../images/landing/everythingElse.svg"
              alt='A donut chart showing that "everything else" constitutes 19% of carbon emissions'
            />
            <h5>Everything Else</h5>
            <p>
              Fashion and services from education to banking make up about 1/5th
              of our emissions. While these have a lower impact individually,
              some services can have a net positive effect on the climate.
            </p>
          </article>
          <div className="source">
            <p>
              Data from{" "}
              <a
                href="https://www.sciencedirect.com/science/article/pii/S0160412019315752"
                target="_blank"
                rel="noopener"
              >
                “Scale, distribution and variations of global greenhouse gas
                emissions driven by U.S. households” by Song, Qu, Taiebat, and
                Wong (2019)
              </a>
            </p>
          </div>
        </div>
      </section>
    </div>

    <section className="landing-section bg-image small padding-top-small">
      <StaticImage
        placeholder="tracedSVG"
        src="../images/landing/homeoffice@2x.png"
        alt="Drawing of a group of three people around a table: On the left there is a woman standing holding a cell phone and a mug. In the middle there is a man is sitting down driking from a mug. On the right another woman is looking at her laptop. The table has a computer, a lamp and a cat on top of it. There is a big window in the background showing buildings outside."
      />
    </section>

    <section
      className="landing-section extra bg-image left"
      style={{ backgroundImage: `url(${HomeOfficeWebp})` }}
    >
      <div className="row">
        <div className="col-12-small col-5 off-0-small off-7">
          <h2>What is Reinventing?</h2>
          <p>
            We’re a new media company focused on helping people like you make a
            difference on climate change. Because here’s the thing: even if we
            switched all our power plants to renewable energy, it still wouldn’t
            be enough. That means that what you do matters. We’re reinventing
            the world, and we want you to be part of it.
          </p>
        </div>
      </div>
    </section>

    <div className="extra-padding">
      <div className="features landing-features">
        <article>
          <span className="icon">
            <StaticImage
              placeholder="tracedSVG"
              src="../images/landing/checklist@2x.png"
              alt="Drawing of a sheet of paper with a checklist of 4 items, all checked."
            />
          </span>
          <div className="content">
            <h3>Guides</h3>
            <p>
              Simple overviews to complex topics: green home renovation and
              financing, subsidies for electric cars, switching to clean energy,
              carbon offsets, and more.
            </p>
          </div>
        </article>
        <article>
          <span className="icon">
            <StaticImage
              placeholder="tracedSVG"
              src="../images/landing/threeusers@2x.png"
              alt="Very simple drawing of three persons."
            />
          </span>
          <div className="content">
            <h3>Directories</h3>
            <p>
              Better than word-of-mouth: vetted listings of climate friendly
              contractors, banks, local businesses, clothing brands, and
              services.
            </p>
          </div>
        </article>
        <article>
          <span className="icon">
            <StaticImage
              placeholder="tracedSVG"
              src="../images/landing/lightbulbon@2x.png"
              alt="Drawing of a light bulb that is turned on."
            />
          </span>
          <div className="content">
            <h3>Explainers</h3>
            <p>
              We go in-depth on complicated subjects, diving into the research
              to help demystify and give nuance to the grey areas of climate
              solutions.
            </p>
          </div>
        </article>
        <article>
          <span className="icon">
            <StaticImage
              placeholder="tracedSVG"
              src="../images/landing/soundon@2x.png"
              alt="Drawing of a speaker with sound waves coming out of it."
            />
          </span>
          <div className="content">
            <h3>Reviews</h3>
            <p>
              We take a Wirecutter-style approach to reviewing products and
              services, with expert interviews, hands-on testing, and a rigorous
              methodology.
            </p>
          </div>
        </article>
      </div>
    </div>

    <div className="landing-bg-color yellow">
      <section className="landing-section">
        <div className="row align-center">
          <div className="col-12">
            <h2>Be the first to find out when we launch</h2>
          </div>
        </div>
        <div className="row aln-center padding-small">
          <div className="col-6 col-12-small col-8-large">
            <Newsletter style="bare" />
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default Landing
